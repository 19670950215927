import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import $ from 'jquery';
import ModalService from '@launchpad/logic/services/ModalService';
import TermsAndConditions from '@launchpad/modules/legal/TermsAndConditions';
import PrivacyPolicy from '@launchpad/modules/legal/PrivacyPolicy';
import AuthorisationsPage from '@launchpad/modules/authorisations/AuthorisationsPage';
import FaqPage from '@launchpad/modules/legal/FaqPage';
import ProfilePage from '../../modules/profile/ProfilePage';
import Dashboard from '../../modules/dashboard/Dashboard';
import CardPage from '../../modules/card/screens/CardPage';
import StatementsPage from '../../modules/statements/StatementsPage';
import NotificationsPage from '../../modules/notifications/NotificationsPage';
import SendMoney from '../../modules/send-money/SendMoney';
import AppTopBar from './components/AppTopBar';
import AppSideBar from './components/AppSideBar';
import AppFooter from './components/AppFooter';
import FxCalculator from '../../modules/fx-calculator/pages/FxCalculator';
import {
  AppRoute,
  BottomWaveForMainPage,
  LPOverridable,
  OverrideService
} from '../../index';
import ConsentsScreen from '@launchpad/modules/open-banking/pages/consents.screen';

class DefaultLayout extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      collapseSideBar: false
    };
  }

  componentDidMount() {
    if (this.props.profile.oneTimePasswordUsed) {
      ModalService.showModal('CHANGE_PASSWORD');
    }
  }

  componentDidUpdate(prevProps: { location: any }) {
    window.scrollTo(0, 0);

    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.setState({
      collapseSideBar: false
    });
  }

  btnHandler = () => {
    this.setState(state => {
      return {
        ...state,
        collapseSideBar: !state.collapseSideBar
      };
    });
  };

  _getInnerElements() {
    const currentUrl = this.props.match.url;

    return (
      <Switch>
        {/* <AppRoute path={`${currentUrl}onepaycards`} load={() => import('../modules/onepaycards/PDLCards')} /> */}
        <AppRoute
          path={`${currentUrl}notifications`}
          component={NotificationsPage}
        />
        {OverrideService.getConfig().rdx && (
          <AppRoute
            path={`${currentUrl}authorisations`}
            component={AuthorisationsPage}
          />
        )}
        <AppRoute path={`${currentUrl}send-money`} component={SendMoney} />
        <AppRoute path={`${currentUrl}statements`} component={StatementsPage} />
        {OverrideService.getConfig().psd2 && (
          <AppRoute path={`${currentUrl}consents`} component={ConsentsScreen} />
        )}
        <AppRoute path={`${currentUrl}card`} component={CardPage} />
        <AppRoute path={`${currentUrl}profile`}>
          <LPOverridable name="route.profile">
            <Route path={`${currentUrl}profile`} component={ProfilePage} />
          </LPOverridable>
        </AppRoute>
        <AppRoute path={`${currentUrl}faq`}>
          <Route path={`${currentUrl}faq`} component={FaqPage} />
        </AppRoute>
        <AppRoute path={`${currentUrl}privacy-policy`}>
          <Route
            path={`${currentUrl}privacy-policy`}
            component={PrivacyPolicy}
          />
        </AppRoute>
        <AppRoute path={`${currentUrl}terms-conditions`}>
          <Route
            path={`${currentUrl}terms-conditions`}
            component={TermsAndConditions}
          />
        </AppRoute>

        <AppRoute exact path={`${currentUrl}`} component={Dashboard} />

        {/* <LPOverridable name="card.page.fx-calculator"> */}
        <AppRoute
          path={`${currentUrl}fx-calculator`}
          component={FxCalculator}
          // authenticatedOnly
        />
        {/* </LPOverridable> */}
      </Switch>
    );
  }

  /**
   * Render default layout
   *
   * @returns {XML}
   */
  render() {
    if (!this.props.profile) return null;

    const innerElements = this._getInnerElements();

    if (this.state.collapseSideBar) {
      $('body').addClass('noscroll');
      $('html').addClass('noscroll');
    } else {
      $('body').removeClass('noscroll');
      $('html').removeClass('noscroll');
    }

    return (
      <div style={{ position: 'relative' }}>
        <div className="phone-menu-holder" onClick={() => this.btnHandler()}>
          <span className="phone-menu-icon" />
          <span className="phone-menu-icon" />
          <span className="phone-menu-icon" />
        </div>
        <AppTopBar />
        <LPOverridable
          name="dashboard.appSideBar"
          isOpen={this.state.collapseSideBar}
        >
          <AppSideBar
            isOpen={this.state.collapseSideBar}
            onPhoneOverlayClick={() => this.btnHandler()}
          />
        </LPOverridable>
        <div className="inner">
          <div className="dashboard-main">
            {innerElements}
            <LPOverridable name="dashboard.bottom">
              <BottomWaveForMainPage />
            </LPOverridable>
          </div>

          <div className="clear" />
        </div>
        <AppFooter key={this.props.localization.locale} />
      </div>
    );
  }
}
const mapStateToProps = (state: {
  auth: { profile: any };
  localization: any;
}) => {
  return {
    profile: state.auth.profile,
    localization: state.localization
  };
};

export default connect(mapStateToProps)(DefaultLayout);
