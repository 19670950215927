import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Api from '@launchpad/logic/api/Api';
import { Redirect } from 'react-router-dom';
import { AppButton, AppIcon, ModalDialog } from '@launchpad/components';
import { OverrideService } from '@launchpad/logic/services';
import ModalService from '@launchpad/logic/services/ModalService';

interface AuthorizationObject {
  id: string;
  action: string;
  actionSpecifier: string;
  title: string;
  description: string;
  publicHash: string;
  privateHash: string;
  status: string;
  authorizationCode: string;
  chanel: string;
  expires: string;
  approved: string;
  rejected: string;
  userId: string;
  mfEnrolmentId: string;
  password: string;
  subject: string;
  reason: string;
  createdAt: string;
  updatedAt: string;
  viewAuthorizationCode?: string;
  authorization_key?: string;
}

function WaitForScaModal({ onClose, authorizationId, apiCallId }) {
  const rejectAnimationRef = useRef<any>();
  const approvedAnimationRef = useRef<any>();
  const expiredAnimationRef = useRef<any>();
  const dispatch = useDispatch();
  const [status, setStatus] = useState('pending');
  const [authorization, setAuthorization] = useState<AuthorizationObject>();

  useEffect(() => {
    triggerPooling();
  }, []);

  useEffect(() => {
    const fetchAuthorizationInterval = setInterval(() => {
      triggerPooling();
    }, 3500);

    if (status !== 'pending') {
      clearInterval(fetchAuthorizationInterval);
    }

    return () => clearInterval(fetchAuthorizationInterval);
  }, [status]);

  async function triggerPooling() {
    if (authorizationId) {
      const response: any = await Api.call(
        `authenticator/v1/authorizations/key/${authorizationId}`
      );
      setAuthorization(response.data);
      if (response.data?.status === 'confirmed') {
        setStatus(response.data.status);
        // Confirm 2fa action again
        Api.callAuthorised(apiCallId, response.data.privateHash);
      }

      setStatus(response.data.status);
    }
  }

  function onCancel() {
    // dispatch({ type: SET_SUBMITTING, isSubmitting: false });
    // onClose();
    ModalService.hideModal();
  }

  function getInProgressElement() {
    return (
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            // backgroundColor: theme.WAIT_FOR_SCA_BG_COLOR,
            backgroundColor: 'white',
            minWidth: 250,
            margin: -30,
            marginRight: 0,
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20
          }}
        >
          {/* <AppLottie
            animationData={require('../../../../../core/assets/animations/online-interview.json')}
            autoplay
            loop={false}
            style={{
              maxWidth: 200,
              width: '100%',
              backgroundColor: 'transparent'
            }}
          /> */}
          <img
            style={{
              width: '80%',
              // height: 40,
              // position: 'absolute',
              bottom: 15,
              left: 15
            }}
            src={OverrideService.getStyles().logo}
            alt="logo"
          />
        </div>
        <div style={{ padding: '0 30px' }}>
          <h3 style={{ textAlign: 'center', paddingBottom: 30 }}>
            <Trans>Complete authentication on your registered device</Trans>
          </h3>
          <p>
            {OverrideService.getOverrides().waitForScaGetInProgressElement ? (
              OverrideService.getOverrides().waitForScaGetInProgressElement()
            ) : (
              <Trans>
                You should receive a push notification to your registered mobile
                device to complete authentication. If you do not receive a push
                notification please go to the “Action List” section to verify.{' '}
              </Trans>
            )}
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderTop: '1px solid gray',
              borderBottom: '1px solid gray',
              margin: '20px 0'
            }}
          >
            <span>
              <Trans>Authentication code: </Trans>
            </span>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: 30,
                margin: '10px 0',
                color: OverrideService.getThisOrOverridenColors().main_color
              }}
            >
              {authorization?.authorizationCode ||
                authorization?.authorization_key}
            </p>
          </div>
          <p style={{ fontSize: 14, textAlign: 'left' }}>
            <Trans>
              <strong>Please note:</strong> You should only approve the
              authentication code if you see the same numbers on your mobile
              device.
            </Trans>
          </p>
          <p style={{ textAlign: 'left', fontSize: 14 }}>
            <Trans>
              If you are having trouble completing authentication, please call
              our friendly customer support team on
            </Trans>{' '}
            {/* <a
              style={{
                color: OverrideService.getThisOrOverridenColors().main_color
              }}
              href={`tel:${OverrideService.getConfig().callCenterNumber}`}
            >
              {OverrideService.getConfig().callCenterNumber}
            </a> */}
          </p>
          <div style={{ marginTop: 20 }}>
            <div>
              <AppButton transparent onClick={() => onCancel()} type="button">
                {/* <AppIcon
                  name="arrow-left-3"
                  size={16}
                  color={OverrideService.getThisOrOverridenColors().main_color}
                  style={{ marginRight: 10 }}
                /> */}
                <Trans>Back</Trans>
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getConfirmedElement() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 450
          }}
        >
          <div>
            {/* <AppLottie
              lottieRef={approvedAnimationRef}
              animationData={require('../../../../../core/assets/animations/success-two-cards.json')}
              loop={false}
              style={{ maxWidth: 150 }}
              autoplay={false}
            /> */}
          </div>
          <h3 style={{ textAlign: 'center', marginBottom: 30 }}>
            <Trans>Authentication attempt approved.</Trans>
          </h3>
          <p style={{ textAlign: 'center' }}>please wait... </p>
        </div>
      </div>
    );
  }

  function getDeniedElement() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 450
          }}
        >
          <div
            style={{
              backgroundColor: OverrideService.getThisOrOverridenColors()
                .main_color,
              borderRadius: '50%',
              padding: 20,
              margin: '20px 0'
            }}
          >
            {/* <AppLottie
              lottieRef={rejectAnimationRef}
              animationData={require('../../../../../core/assets/animations/cancel-x-animation.json')}
              loop={false}
              style={{ maxWidth: 50 }}
              autoplay={false}
            /> */}
          </div>
          <h3 style={{ textAlign: 'center', marginBottom: 30 }}>
            <Trans>Authentication attempt has been denied!</Trans>
          </h3>
          <p style={{ fontSize: 14 }}>
            <Trans>
              If you are having trouble completing authentication, please call
              our friendly customer support team on
            </Trans>{' '}
            {/* <a
              style={{
                color: OverrideService.getThisOrOverridenColors().main_color
              }}
              href={`tel:${OverrideService.getConfig().callCenterNumber}`}
            >
              {OverrideService.getConfig().callCenterNumber}
            </a> */}
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <AppButton transparent onClick={() => onCancel()} type="button">
              {/* <AppIcon
                name="arrow-left-3"
                size={16}
                color={OverrideService.getThisOrOverridenColors().main_color}
                style={{ marginRight: 10 }}
              /> */}
              <Trans>Back</Trans>
            </AppButton>
          </div>
        </div>
      </div>
    );
  }

  function getExpiredElement() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 450
          }}
        >
          <div
            style={{
              backgroundColor: OverrideService.getThisOrOverridenColors()
                .main_color,
              borderRadius: '50%',
              padding: 10,
              margin: '20px 0',
              width: 80,
              height: 80
            }}
          >
            {/* <AppLottie
              lottieRef={expiredAnimationRef}
              initialSegment={[0, 50]}
              animationData={require('../../../../../core/assets/animations/alert-white.json')}
              loop={false}
              style={{
                maxWidth: 60,
                marginBottom: 10
              }}
              autoplay={false}
            /> */}
          </div>
          <h3 style={{ textAlign: 'center', marginBottom: 20 }}>
            <Trans>Authentication attempt has expired!</Trans>
          </h3>
          <p style={{ fontSize: 14 }}>
            <Trans>
              If you are having trouble completing authentication, please call
              our friendly customer support team on
            </Trans>{' '}
            {/* <a
              style={{
                color: OverrideService.getThisOrOverridenColors().main_color
              }}
              href={`tel:${OverrideService.getConfig().callCenterNumber}`}
            >
              {OverrideService.getConfig().callCenterNumber}
            </a> */}
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <AppButton transparent onClick={() => onCancel()} type="button">
              {/* <AppIcon
                name="arrow-left-3"
                size={16}
                color={OverrideService.getThisOrOverridenColors().main_color}
                style={{ marginRight: 10 }}
              /> */}
              <Trans>Back</Trans>
            </AppButton>
          </div>
        </div>
      </div>
    );
  }

  let element: JSX.Element | null = null;

  switch (status) {
    case 'confirmed':
      element = getConfirmedElement();
      setTimeout(() => {
        approvedAnimationRef.current.play();
      }, 1000);
      break;

    case 'rejected':
      element = getDeniedElement();
      setTimeout(() => {
        rejectAnimationRef.current.play();
      }, 1000);
      break;

    case 'expired':
      element = getExpiredElement();
      setTimeout(() => {
        expiredAnimationRef.current.play();
      }, 1000);
      break;

    default:
      element = getInProgressElement();
  }

  if (!authorizationId) return <Redirect to="/" />;

  return (
    <ModalDialog show onClose={onCancel}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          position: 'relative',
          borderRadius: '20px',
          minWidth: '300px',
          maxWidth: '750px',
          // maxHeight: '850px',
          paddingLeft: '30px',
          paddingRight: '30px'
        }}
      >
        <AppIcon
          name="close"
          onClick={() => onCancel()}
          style={{ position: 'absolute', top: 10, right: 10 }}
        />
        <div
          style={{ padding: '0 30px', margin: '30px 0', textAlign: 'center' }}
        >
          {element}
        </div>
      </div>
    </ModalDialog>
  );
}

export default WaitForScaModal;
