export const OB_REVOKE_CONSENT = 'OP_REVOKE_CONSENT';
export const OB_VALIDATE_CONSENT = 'OB_VALIDATE_CONSENT';

export interface OpenBankingConsentType {
  createdAt: string;
  updatedAt: string;
  createdById: null | string;
  updatedById: null | string;
  metadata: {
    providerResponse: {
      Scope: string;
      AppName: string;
      TppName: string;
      ConsentId: string;
      GatewayId: string;
      AppWebsite: string;
      TppWebsite: string;
      Environment: string;
      PaymentType: string;
      Permissions: string[];
      ConsentExpiry: null | string;
      ConsentStatus: string;
      GatewayDomain: string;
      AppDescription: string;
      ConsentGranted: null | string;
      ConsentRevoked: null | string;
      ConsentedUserId: null | string;
      ConsentedAccounts: null | any[];
      ConsentRequestExpiry: string;
      ConsentRequestCreated: string;
      PaymentInformation?: {
        creditorAccount: { iban: string; currency: string };
        creditorName: string;
        debtorAccount: { iban: string; currency: string };
        instructedAmount: { amount: string; currency: string };
        remittanceInformationUnstructured: string;
      };
    };
  };
  tenantId: string;
  id: string;
  userId: string;
  accounts: any[];
  provider: string;
  providerReference: string;
  scope: string;
  tppName: string;
  status: string;
  callbackUrl: null | string;
  redirectionUrl: null | string;
  requestExpiresAt: string;
  consentExpiresAt: null | string;
}

export interface RevokeConsentAction {
  type: typeof OB_REVOKE_CONSENT;
  id: string;
  callback?: () => void;
}

export interface ValidateConsentAction {
  type: typeof OB_REVOKE_CONSENT;
  id: string;
  callback: (valid: boolean) => void;
}
