import { all, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  OB_REVOKE_CONSENT,
  OB_VALIDATE_CONSENT,
  RevokeConsentAction,
  ValidateConsentAction
} from '../ob.types';
import Api from '@launchpad/logic/api/Api';
import { MEMBER_AUTH_LOADED } from '@launchpad/modules/auth/AuthActions';
import ModalService from '@launchpad/logic/services/ModalService';

export function* revokeConsentSaga(action: RevokeConsentAction) {
  try {
    const result = yield Api.call(`openbanking/consents/${action.id}/revoke`);
    if (result.data) {
      if (action.callback) {
        action.callback();
      }
      // yield put({ type: RELOAD_APP_LIST, listKey: 'openbanking/consents' });
    }
  } catch (error) {
    // Handled by Api.ts
    console.log('Unexpected error:', error);
  }
}

export function* validateConsentSaga(action: ValidateConsentAction) {
  try {
    const result = yield Api.call(`openbanking/consents/${action.id}/validate`);

    if (result.data.validation === false) {
      // navigate to incorrect details screen
      // yield put(
      //   push('/ob-consent-incorrect-details', { consentId: action.id })
      // );
      action.callback(false);
    }
  } catch (error) {
    // Handled by Api.ts
    console.log('Unexpected error:', error);
  } finally {
    action.callback(true);
  }
}

export function* userEnteredAppSaga() {
  try {
    const consentId = yield localStorage.getItem('consentId');
    const callbackUrl = yield localStorage.getItem('callbackUrl');
    if (consentId) {
      // TODO: fetch consent details
      const result = yield Api.call(`openbanking/consents`, {
        ConsentId: consentId,
        CallbackURL: callbackUrl
      });
      if (result?.data) {
        // Show consent id modal
        const consent = result.data;
        ModalService.showModal('CONSENT_DETAILS', { consent });
      }
    }
  } catch (error) {
    // Handled by Api.ts
    console.log('Unexpected error:', error);
  }
}

export default function* openBankingSagas(): SagaIterator {
  yield all([takeLatest(OB_REVOKE_CONSENT, revokeConsentSaga)]);
  yield all([takeLatest(OB_VALIDATE_CONSENT, validateConsentSaga)]);
  yield all([takeLatest(MEMBER_AUTH_LOADED, userEnteredAppSaga)]);
}
