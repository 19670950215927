import React from 'react';
import DashboardHeader from '../../dashboard/components/DasboardHeader';
import ConsentsModel from '../components/consents.component';

const ConsentsScreen = () => {
  return (
    <div className="appContainer">
      <div>
        <ConsentsModel />
      </div>
    </div>
  );
};

export default ConsentsScreen;
