/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OB_VALIDATE_CONSENT, OpenBankingConsentType } from '../ob.types';
import {
  AppButton,
  AppIcon,
  ModalDialog,
  SwitchButton
} from '@launchpad/components';
import { OverrideService } from '@launchpad/logic/services';
import { CircularProgress } from '@material-ui/core';
import NumberHelper from '@launchpad/util/NumberHelper';
import Api from '@launchpad/logic/api/Api';
import ModalService from '@launchpad/logic/services/ModalService';
import ToastHelper from '@launchpad/util/ToastHelper';
import i18n from '@launchpad/i18n';

function OBConsentDetailsScreen({
  consent
}: {
  consent: OpenBankingConsentType;
}) {
  const dispatch = useDispatch();
  const [isApproving, setIsApproving] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const accounts = useSelector((state: any) => state.accounts);
  const [selectedAccounts, setSelectedAccounts] = useState<
    Array<{ id: string }>
  >([]);
  const [isValidating, setIsValidating] = useState(false);

  const paymentConsentData =
    consent?.metadata?.providerResponse?.PaymentInformation || null;

  useEffect(() => {
    // dispatch({
    //   type: OB_VALIDATE_CONSENT,
    //   id: consent.id,
    //   callback: () => setIsValidating(false)
    // });
    // Remove consentId from localStorage
    localStorage.removeItem('consentId');
    localStorage.removeItem('callbackUrl');
  }, []);

  // useEffect(() => {
  //   if (!isValidating) {
  //     dispatch({ type: FETCH_ACCOUNTS_START });
  //   }
  // }, [isValidating]);

  useEffect(() => {
    // If there is no option to select accounts, select the first one
    if (
      !OverrideService.getConfig().allowAccountSelectionPsd2 &&
      accounts.length > 0
    ) {
      setSelectedAccounts([{ id: accounts[0]?.id }]);
    }
  }, [accounts]);

  if (!consent) {
    window.location.replace('/');
  }

  const handleSubmit = async (confirmed: boolean) => {
    // TODO: move this to openbanking saga
    try {
      // eslint-disable-next-line no-unused-expressions
      confirmed ? setIsApproving(true) : setIsDeclining(true);
      const response: any = await Api.call(
        `openbanking/consents/${consent.id}/${
          confirmed ? 'approve' : 'revoke'
        }`,
        {
          accounts: selectedAccounts
        }
      );
      if (response.success) {
        // Redirect to returned url
        const redirectionUrl =
          response.data?.redirectionUrl || response.data?.data?.redirectionUrl;
        if (redirectionUrl) {
          window.location.replace(redirectionUrl);
        }
      } else {
        ToastHelper.show(response.message, 'error');
        ModalService.hideModal();
      }
    } catch (error) {
      console.log('ERR:', error);
    } finally {
      setIsApproving(false);
      setIsDeclining(false);
    }
  };

  function renderConsentDetails() {
    return (
      <div style={{ marginTop: 20 }}>
        <p>
          {consent.tppName}{' '}
          <Trans>
            is requesting your approval to access your{' '}
            {OverrideService.getStyles().APP_NAME} account information and to
            process data on its behalf. This data includes your transaction data
            and account information, including the balance.
          </Trans>
        </p>
        {OverrideService.getConfig().allowAccountSelectionPsd2 && (
          <>
            <p style={{ marginBottom: 10 }}>
              <Trans>
                Please select the accounts you wish to grant access to:
              </Trans>
            </p>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                overflowY: 'auto',
                maxHeight: 400
              }}
            >
              {accounts.map((account, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: 15,
                    marginBottom: 15,
                    borderBottom:
                      index === accounts.length - 1
                        ? 'none'
                        : '1px solid #D6D8DB'
                  }}
                >
                  <span style={{ marginRight: 20 }}>
                    {account.name || 'Account name'}
                  </span>
                  <SwitchButton
                    isChecked={selectedAccounts.some(
                      acc => acc.id === account.id
                    )}
                    onChange={isEnabled => {
                      if (isEnabled) {
                        setSelectedAccounts([
                          ...selectedAccounts,
                          { id: account.id }
                        ]);
                      } else {
                        setSelectedAccounts(
                          selectedAccounts.filter(acc => acc.id !== account.id)
                        );
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
        <p style={{ marginBottom: 30 }}>
          By proceeding below, you accept that you are duly authorized
          signatory, and agree to grant read access to selected{' '}
          {OverrideService.getStyles().APP_NAME} account.
        </p>
      </div>
    );
  }

  function renderPaymentDetails() {
    const amount = paymentConsentData?.instructedAmount.amount
      ? parseFloat(paymentConsentData?.instructedAmount.amount)
      : 0;

    return (
      <div>
        <p style={{ marginBottom: 10 }}>
          {consent.tppName}
          <Trans>
            {' '}
            requests to initiate the following one-time payment via its trusted
            partner{' '}
            <strong>{consent?.metadata?.providerResponse?.AppName}</strong> with
            respect to your {OverrideService.getStyles().APP_NAME} account. To
            consent to this transaction, check the details bellow:
          </Trans>
        </p>
        <div style={{ marginTop: 10 }}>
          <ul style={{ padding: 0 }}>
            <li>
              <Trans>Payee name:</Trans>{' '}
              <span style={{ color: 'black' }}>
                {paymentConsentData?.creditorName}{' '}
              </span>
            </li>
            <li>
              <Trans>Payee IBAN:</Trans>{' '}
              <span style={{ color: 'black' }}>
                {paymentConsentData?.creditorAccount.iban}{' '}
              </span>
            </li>
            <li>
              <Trans>Description:</Trans>{' '}
              <span style={{ color: 'black' }}>
                {paymentConsentData?.remittanceInformationUnstructured}
              </span>
            </li>
            <li>
              <Trans>Amount:</Trans>{' '}
              <span style={{ color: 'black' }}>
                {NumberHelper.currency(amount, true)}
              </span>
            </li>
          </ul>
        </div>
        {OverrideService.getConfig().allowAccountSelectionPsd2 && (
          <>
            <p style={{ marginBottom: 10 }}>
              <Trans>
                Please select account you want to initiate a payment:
              </Trans>
            </p>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                overflowY: 'auto',
                maxHeight: 400
              }}
            >
              {accounts.map((account, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: 15,
                    marginBottom: 15,
                    borderBottom:
                      index === accounts.length - 1
                        ? 'none'
                        : '1px solid #D6D8DB'
                  }}
                >
                  <span style={{ marginRight: 20 }}>
                    {account.name || 'Account name'}
                  </span>
                  <SwitchButton
                    isChecked={selectedAccounts.some(
                      acc => acc.id === account.id
                    )}
                    onChange={isEnabled => {
                      if (isEnabled) {
                        setSelectedAccounts([
                          ...selectedAccounts,
                          { id: account.id }
                        ]);
                      } else {
                        setSelectedAccounts(
                          selectedAccounts.filter(acc => acc.id !== account.id)
                        );
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <ModalDialog id="CONSENT_DETAILS" show>
      <div
        className="transaction-modal"
        style={{
          minHeight: 'auto',
          height: 'auto',
          overflowY: 'auto',
          position: 'relative',
          padding: 30
        }}
      >
        <div
          className="close-btn"
          style={{ position: 'absolute', top: 10, right: 10 }}
          onClick={() => ModalService.hideModal()}
        >
          <AppIcon
            name="fa.times"
            style={{ fontSize: 24, cursor: 'pointer' }}
          />
        </div>
        <div>
          <span style={{ fontSize: 20 }}>
            {paymentConsentData
              ? i18n.t('Consent to Payment Initiation')
              : i18n.t('Access to account information')}
          </span>
        </div>
        {isValidating ? (
          <div>
            <CircularProgress
              style={{
                color: OverrideService.getThisOrOverridenColors().main_color
              }}
              size={20}
            />
          </div>
        ) : paymentConsentData ? (
          renderPaymentDetails()
        ) : (
          renderConsentDetails()
        )}
        {!isValidating && (
          <div
            style={{ display: 'flex', marginTop: paymentConsentData ? 0 : 40 }}
          >
            <AppButton
              isLoading={isDeclining}
              transparent
              onClick={() => handleSubmit(false)}
              type="button"
              customstyle={{ maxWidth: 450, maxHeight: 60 }}
            >
              <Trans>Cancel</Trans>
            </AppButton>
            <div style={{ width: 20 }} />
            <AppButton
              isLoading={isApproving}
              onClick={() => handleSubmit(true)}
              type="button"
              disabled={
                selectedAccounts.length === 0 &&
                OverrideService.getConfig().allowAccountSelectionPsd2
              }
            >
              <Trans>Proceed</Trans>
            </AppButton>
          </div>
        )}
      </div>
    </ModalDialog>
  );
}

export default OBConsentDetailsScreen;
