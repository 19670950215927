import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  AppButton,
  BottomWaveForLoadingPage,
  OverrideService,
  LPOverridable
} from '../../../index';
import { Trans } from 'react-i18next';
import LanguageSwitcher from '@launchpad/components/layout/components/LanguageSwitcher';

const WelcomePage: React.FC = () => {
  const [language, setLanguage] = useState('');
  const history = useHistory();
  const location = useLocation();
  const localization = useSelector((state: any) => state.localization);

  useEffect(() => {
    const localTheme = localStorage.getItem('AppTheme');
    document.documentElement.className = '';
    document.documentElement.classList.add(`theme-${localTheme}`);

    // Handle consentId and callbackURL for openbanking
    if (location.pathname.includes('tokens')) {
      const searchParams = new URLSearchParams(location.search);
      const consentId = searchParams.get('ConsentId');
      const callbackURL = searchParams.get('CallbackURL');
      if (consentId || callbackURL) {
        localStorage.setItem('consentId', consentId || '');
        localStorage.setItem('callbackUrl', callbackURL || '');
      }
    }
  }, []);

  useEffect(() => {
    setLanguage(localization.locale);
  }, [localization.locale]);

  const goToLoginPage = () => {
    history.push('/login');
  };

  return (
    <LPOverridable name="welcome.page.content" history={history}>
      <div className="login-overlay-container" key={language}>
        <img
          className="logo"
          src={
            OverrideService.getStyles().logo ||
            require('../../../assets/Launchpad_logoWhite.png')
          }
          alt="logo"
        />
        {OverrideService.getOverrides()['hide-language-switcher']
          ? null
          : localization.languages && (
              <div className="auth-language-holder">
                <LanguageSwitcher showCurrentLanguageName />
              </div>
            )}
        <img
          src={require('../../../assets/overlay_background.png')}
          className="overlay-background"
          alt=""
        />
        <div className="center-element">
          <h1>
            <Trans>Welcome to Launchpad</Trans>
          </h1>
          <h3>
            <Trans>Launch Your Digital Banking Strategy with us</Trans>
          </h3>
          <AppButton onClick={goToLoginPage}>
            <Trans>SIGN IN</Trans>
          </AppButton>
        </div>

        <BottomWaveForLoadingPage />

        <div className="sign-up-holder">
          <p>
            <Trans>New user?</Trans>
          </p>
          <Link to="" className="sign-up-link">
            <Trans>Sign Up</Trans>
          </Link>
        </div>
      </div>
    </LPOverridable>
  );
};

export default WelcomePage;
