import { push } from 'connected-react-router';
import appStore from '../store/AppStore';
import ModalService from './ModalService';
import Api from '../api/Api';

interface ActionType {
  [key: string]: any;
}
const actions: ActionType = {};

export default class SCAService {
  static registerAction(action: any, actionName = '') {
    if (actionName) {
      actions[actionName] = action;
      return actionName;
    }
    const hash = this.generateHash();
    actions[hash] = action;
    return hash;
  }

  static getAction(actionName = '') {
    if (actionName && actions.actionName) {
      const foundAction = actions.actionName;
      delete actions.actionName;
      return foundAction;
    }
    if (Object.keys(actions).length > 0) {
      const foundAction = actions[Object.keys(actions)[0]];
      delete actions[Object.keys(actions)[0]];
      return foundAction;
    }
    return [];
  }

  static registerApiCall(action: any, actionName = '') {
    if (actionName) {
      actions[actionName] = action;
      return actionName;
    }
    const hash = this.generateHash();
    actions[hash] = action;
    return hash;
  }

  static getApiCall(actionName = '') {
    if (actionName && actions.actionName) {
      const foundAction = actions.actionName;
      delete actions.actionName;
      return foundAction;
    }
    if (Object.keys(actions).length > 0) {
      const foundAction = actions[Object.keys(actions)[0]];
      delete actions[Object.keys(actions)[0]];
      return foundAction;
    }
    return [];
  }

  static handleScaCode(apiCallId: string, payload?: any) {
    try {
      const { token } = appStore.getState().auth;
      if (token) {
        ModalService.hideModal();

        if (payload.channel === 'device') {
          // Show wait for device to approve modal
          return ModalService.showModal('WAIT_FOR_SCA', {
            isAppModal: true,
            apiCallId,
            authorizationId: payload.authorizationId || payload.authorizationKey
          });
        }

        // Show modal
        return ModalService.showModal('GENERAL_INSERT_PASSCODE', {
          isAppModal: true,
          apiCallId,
          hash: payload.hash,
          callback: data => {
            // const hash = data?.nextState?.payload?.scaHash;
            const hash = data?.nextState?.payload?.hash;
            Api.callAuthorised(apiCallId, hash);
          }
        });
      }
      // redirect to sca screen
      // if (payload.channel === 'sca' || payload.channel === 'device') {
      //   // Redirect to authorization pending screen
      //   return appStore.dispatch(
      //     push('/wait-for-sca', {
      //       authorizationId: payload.authorizationId,
      //       apiCallId,
      //       publicHash: payload.publicHash,
      //       exposed: payload.publicHash && payload.channel === 'device'
      //     })
      //   );
      // }
      // TODO: handle sca base on url
      // return appStore.dispatch(push('/sca-code-verification', { apiCallId }));
    } catch (error) {
      console.log('error:', error);
    }
  }

  // We do not need hash for now
  static generateHash() {
    return (
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
    );
  }
}
