import { combineReducers } from 'redux';
import {
  MEMBER_LOGIN_TRIGGER,
  MEMBER_TOKEN_LOADED,
  MEMBER_LOGOUT_TRIGGER,
  MEMBER_AUTH_LOADED,
  MEMBER_TOKEN_PRELOAD,
  SET_AUTHORIZATION_REQUEST
} from './AuthActions';

function token(state = null, action) {
  switch (action.type) {
    case MEMBER_TOKEN_PRELOAD:
      return action.payload;
    case MEMBER_LOGIN_TRIGGER:
      return action.payload.token;
    case MEMBER_TOKEN_LOADED:
      return action.payload;
    case MEMBER_LOGOUT_TRIGGER:
      return null;
  }
  return state;
}

function profile(state = null, action) {
  switch (action.type) {
    case MEMBER_AUTH_LOADED:
      return action.payload.profile;
    case SET_AUTHORIZATION_REQUEST:
      return {
        ...state.profile,
        authorizationRequest: action.authorizationRequest
      };
    case MEMBER_LOGOUT_TRIGGER:
      return null;
  }
  return state ? { ...state } : state;
}

export default combineReducers({
  token,
  profile
});
