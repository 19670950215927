import { AppButton, AppList } from '@launchpad/components';
import BaseModel from '@launchpad/logic/model/BaseModel';
import DateHelper from '@launchpad/util/DateHelper';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { OB_REVOKE_CONSENT } from '../ob.types';

export interface ConsentType {
  accounts: any[];
  callbackUrl: string;
  consentExpiresAt: string | null;
  createdAt: string;
  createdById: string | null;
  id: string;
  provider: string;
  providerReference: string;
  redirectionUrl: string;
  requestExpiresAt: string | null;
  scope: string;
  status: string;
  tenantId: string;
  tppName: string;
  updatedAt: string;
  updatedById: string | null;
  userId: string;
  metadata?: {
    providerResponse?: {
      AppName: string;
      AppWebsite: string;
    };
  };
}

function ConsentItem({
  item,
  refreshList
}: {
  item: ConsentType;
  refreshList: () => void;
}) {
  const consentDispatch = useDispatch();
  const [clickedItem, setClickedItem] = useState('');

  return (
    <tr className="logDataRow">
      <td>{item.tppName}</td>
      <td>{item.metadata?.providerResponse?.AppName}</td>
      <td>{item.metadata?.providerResponse?.AppWebsite}</td>
      <td>
        {item.status?.toLowerCase() === 'request_expired'
          ? 'EXPIRED'
          : item.status}
      </td>
      <td>{DateHelper.date(item.createdAt)}</td>
      <td>{item.consentExpiresAt && DateHelper.date(item.consentExpiresAt)}</td>
      <td>
        <AppButton
          disabled={item.status?.toLowerCase() !== 'active'}
          isLoading={clickedItem === item.id}
          onClick={() => {
            setClickedItem(item.id);
            consentDispatch({
              type: OB_REVOKE_CONSENT,
              id: item.id,
              callback: () => {
                refreshList();
              }
            });
          }}
        >
          <Trans>REVOKE</Trans>
        </AppButton>
      </td>
    </tr>
  );
}

function Consents() {
  const list = useRef(null);

  function refreshList() {
    if (list) {
      (list.current as any)._reset().then(() => {
        (list.current as any)._refresh();
      });
    }
  }

  return (
    <div id="consent-list-wrapper">
      <div className="container">
        <h3>
          <Trans>List of consents</Trans>
        </h3>
        <table className="consent-table">
          <thead>
            <tr>
              <th>
                <Trans>TPP NAME</Trans>
              </th>
              <th>
                <Trans>APP NAME</Trans>
              </th>
              <th>
                <Trans>WEBSITE</Trans>
              </th>
              <th>
                <Trans>STATUS</Trans>
              </th>
              <th>
                <Trans>CREATED AT</Trans>
              </th>
              <th>
                <Trans>EXPIRES AT</Trans>
              </th>
              <th>
                <Trans>ACTION</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            <AppList
              onInit={(appListRef: any) => {
                list.current = appListRef;
              }}
              model={ConsentsModel}
              pagePagination
              params={{ limit: 5 }}
              renderItem={item => {
                return (
                  <ConsentItem item={item} refreshList={() => refreshList()} />
                );
              }}
              wrapperStyle={{ display: 'contents' }}
              renderNoItems={() => (
                <tr>
                  <td colSpan={7} style={{ textAlign: 'center', fontSize: 14 }}>
                    <Trans>No new consents</Trans>
                  </td>
                </tr>
              )}
              renderShowMore={(data, cb) => {
                return (
                  <div
                    style={{
                      position: 'absolute',
                      left: '44%',
                      marginTop: 20
                    }}
                  >
                    <AppButton
                      onClick={() => cb()}
                      style={{ maxHeight: 40, minHeight: 'auto' }}
                    >
                      <Trans>Load more</Trans>
                    </AppButton>
                  </div>
                );
              }}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Consents;

class ConsentsModel extends BaseModel {
  getListUrl(): any {
    return 'openbanking/consents/list';
  }
}
