import ApprovedTransactionModal from '../authorisations/components/ApprovedTransactionModal';
import AuthorizationDetailsModal from '../authorisations/components/AuthorizationDetailsModal';
import InsertPasscodeModal from '../authorisations/components/InsertPasscodeModal';
import WaitForScaModal from './models/wait-for-sca/wait-for-sca-modal.component';

export const modals = {
  AUTHORISATION_DETAILS_MODAL: AuthorizationDetailsModal,
  GENERAL_INSERT_PASSCODE: InsertPasscodeModal,
  APPROVED_TRANSACTION: ApprovedTransactionModal,
  WAIT_FOR_SCA: WaitForScaModal
};

export default modals;
