import axios from 'axios';
import Logger from '../../util/Logger';
import store from '../store/AppStore';
import Config from '../../config';
import { SET_AUTHORIZATION_REQUEST } from '@launchpad/modules/auth/AuthActions';
import SCAService from '../services/SCAService';

export default class Api {
  static apiCalls: any[] = [];

  static getBaseUrl() {
    if (Config.env === 'live') {
      return Config.url;
    }
    return Config.url;
  }

  static callAuthorised(apiCallId: string, hash: string) {
    // Old implementation, not used anymore because used api call was not removed from array
    // const apiCallDefinition = Api.apiCalls.find((x) => x.id === apiCallId);

    // Remove from array instead of find
    let apiCallDefinition;
    const index = Api.apiCalls.findIndex(x => x.id === apiCallId);
    if (index !== -1) {
      // eslint-disable-next-line prefer-destructuring
      apiCallDefinition = Api.apiCalls.splice(index, 1)[0];
    }
    if (!apiCallDefinition) return;

    new Promise(apiCallDefinition.callGenerator(hash))
      .then(data => {
        apiCallDefinition.mainResolve(data);
      })
      .catch(e => {
        apiCallDefinition.mainReject(e);
      })
      .finally(() => {
        if (apiCallDefinition?.timeout) {
          clearTimeout(apiCallDefinition.timeout);
        }
        // store.dispatch({ type: SET_SUBMITTING, isSubmitting: false });
      });
  }

  /**
   * PSD2 implementation
   *
   * Intercept all api calls. Store api call in array in case promise does not finish (BE returned 412).
   * If BE returns 412, user should authorize request first, then initial request will be repeated automatically.
   */
  static call(url: string | number, data: any = null, onProgress: any = null) {
    return new Promise((resolve, reject) => {
      const hash = SCAService.generateHash();
      let mainResolve = null;
      let mainReject = null;
      // SCA Hash will be set in header when the call gets triggered for the second time
      const actualApiCallPromiseArgument = (scaHash: any) => {
        return (res: any, rej: any) => {
          mainResolve = res;
          mainReject = rej;
          Api.apiCall(url, data, onProgress, hash, scaHash)
            .then(resp => {
              resolve(resp);
            })
            .catch(e => {
              reject(e);
            });
        };
      };
      const newPromise = new Promise(actualApiCallPromiseArgument(null));

      // TODO: check clean up
      // const timeout = setTimeout(() => {
      //   console.log('10 seconds finished');
      //   Api.apiCalls = Api.apiCalls.filter(x => x.id !== hash);
      // }, 10000);

      Api.apiCalls.push({
        id: hash,
        callGenerator: actualApiCallPromiseArgument,
        originalPromise: newPromise,
        mainResolve,
        mainReject
        // timeout
      });
      return newPromise
        .then(res => {
          // Remove from array
          setTimeout(() => {
            Api.apiCalls = Api.apiCalls.filter(x => x.id !== hash);
          }, 100);
          resolve(res);
        })
        .catch(() => {
          // Remove from array
          setTimeout(() => {
            Api.apiCalls = Api.apiCalls.filter(x => x.id !== hash);
          }, 100);
          reject(data);
        });
    });
  }

  static apiCall(
    url: string | number,
    data: any = null,
    onProgress: any = null,
    apiCallId: string,
    scaHash?: string
  ) {
    const requestUrl = this.getBaseUrl() + url;
    const ajaxOptions: any = {
      headers: {
        'Content-Type': 'application/json'
        // ...(scaHash ? { 'Authorization-Hash': scaHash } : {})
      },
      method: 'POST',
      contentType: 'application/json',
      body: JSON.stringify(data)
    };
    const authToken = store.getState().auth.token || null;

    if (authToken !== null) {
      ajaxOptions.headers['Authorization'] = 'Bearer ' + authToken;
    }
    Logger.log('api options', requestUrl, ajaxOptions);
    Logger.log('api request', requestUrl, data);

    return new Promise((resolve, reject) => {
      // const config =  {
      //   onUploadProgress: function(progressEvent: any) {
      //     var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
      //     console.log(percentCompleted)
      //   }
      // }
      axios({
        url: this.getBaseUrl() + url,
        method: 'POST',
        // contentType: 'application/json',
        headers: ajaxOptions['headers'],
        data: JSON.stringify(data),
        onUploadProgress: function(progressEvent: any) {
          if (onProgress) {
            onProgress(progressEvent);
          }
        }
      })
        .then((response: any) => {
          Logger.log('api response', requestUrl, response.data);
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error.response) {
            Logger.log('data', error.response);

            // Handle SCA flow if 412
            if (error.response.status === 412) {
              const scaPayload =
                error?.response?.data?.data?.nextState?.payload;
              // Set publicHash
              store.dispatch({
                type: SET_AUTHORIZATION_REQUEST,
                authorizationRequest: {
                  publicHash: scaPayload.scaHash,
                  channel: scaPayload?.channel
                }
              });
              // Handle how user will enter OTP
              SCAService.handleScaCode(apiCallId, scaPayload);
              // Do not finish promise, wait for SCA action to finished
              // eslint-disable-next-line consistent-return
              return;
            }

            if (error.response.status === 403) {
              localStorage.removeItem('AUTH_TOKEN');
              window.location.replace('/welcome');
              // store.dispatch(logoutTriggerAction());
            }

            if (error.response.status === 200) {
              let code = error.response.data.code;

              if (code === 401 || code === 403) {
                localStorage.removeItem('AUTH_TOKEN');
                window.location.replace('/welcome');
                // store.dispatch(logoutTriggerAction())
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
          reject(error.response ? error.response.data : error);
        });
    });
  }
}
